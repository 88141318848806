import React, { Fragment } from 'react';
import { RichText, Text, withSitecoreContext, Image } from '@sitecore-jss/sitecore-jss-react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './carouselProductOver.scss';
import './productSummary.scss';
import ProductRating from '../ProductRating';
import i18next from 'i18next';
import ReactComponent from '../../ReactComponent';
import ProductImagesGalleryV2 from '../ProductImagesGalleryV2';
import ProductReviews from '../../utils/ProductReviews';
import { handleClickToBuy, handleClickVTO, handleWriteReview } from '../Tagging';
import ReactTooltip from 'react-tooltip';
import ProductPIL from '../ProductPIL';

class ProductSummary extends ReactComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      selectedTint: 0,
    };
  }

  componentDidMount() {
    // scroll fix
    window.scrollTo(0, 0);

    // VTO
    if (
      this.props.sitecoreContext.route.fields.productTints &&
      this.props.sitecoreContext.route.fields.productTints.length !== 0 &&
      this.props.sitecoreContext.route.fields.productVTOCategory.value !== ''
    ) {
      this.loadDropper();
      this.updateDsfAppProperties();
    }

    // JoinStory
    this.callJoinsStoryScript();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.sitecoreContext.route.name !== this.props.sitecoreContext.route.name) {
      if (
        document.querySelector('#dropper-script') &&
        this.props.sitecoreContext.route.fields.productTints &&
        this.props.sitecoreContext.route.fields.productTints.length !== 0 &&
        this.props.sitecoreContext.route.fields.productVTOCategory.value !== ''
      ) {
        this.setState({ selectedTint: 0 }, () => {
          this.loadDropper();
          this.updateDsfAppProperties();
        });
      }
    }

    if (
      this.props.sitecoreContext.route.fields.joinStoryID.value !== '' &&
      document.getElementById('joinstoryscript')
    ) {
      document.getElementById('joinstoryscript').remove();
      this.callJoinsStoryScript();
    }
  }

  callJoinsStoryScript() {
    if (this.props.sitecoreContext.route.fields.joinStoryID.value !== '') {
      const joinStoryID = this.props.sitecoreContext.route.fields.joinStoryID.value;
      const script = document.createElement('script');
      script.setAttribute('id', 'joinstoryscript');
      script.setAttribute(
        'src',
        'https://la-provencale-bio.my.join-stories.com/widgets/' + joinStoryID + '/index.js'
      );
      script.setAttribute('data-join-widget-id', 'list-bubble-' + joinStoryID);
      script.setAttribute('data-join-widget-alias', joinStoryID);
      document.head.appendChild(script);
    }
  }

  updateDsfAppProperties() {
    // Update values for dsf-app
    const vto_category = this.props.sitecoreContext.route.fields.productVTOCategory.value;
    const vto_upc_selected =
      this.props.sitecoreContext.route.fields.productTints[this.state.selectedTint].fields.EanCode
        .value;
    let upc_temp = [];
    this.props.sitecoreContext.route.fields.productTints.forEach((upc) => {
      upc_temp.push(upc.fields.EanCode.value);
    });
    let upc_list = upc_temp.join();
    const vto_upc_list = upc_list;

    document.querySelector('#dsf-element').setAttribute('vto_category', vto_category);
    document.querySelector('#dsf-element').setAttribute('vto_upc_list', vto_upc_list);
    document.querySelector('#dsf-element').setAttribute('vto_upc_selected', vto_upc_selected);
  }

  loadDropper() {
    const url = new URL(window.location.href);
    let params = new URLSearchParams(window.location.search);

    // Change selected tint if param in URL
    if (params.has('ean')) {
      this.props.sitecoreContext.route.fields.productTints.map((item, index) => {
        if (item.fields.EanCode.value === params.get('ean')) {
          this.setState({ selectedTint: index });
        }
      });
    } else {
      // Add param tint in URL
      params.set(
        'tint',
        this.props.sitecoreContext.route.fields.productTints[this.state.selectedTint].fields.Name
          .value.toLowerCase()
      );
      params.set(
        'ean',
        this.props.sitecoreContext.route.fields.productTints[this.state.selectedTint].fields.EanCode
          .value
      );
      window.history.replaceState(
        {},
        '',
        decodeURIComponent(`${window.location.pathname}?${params}`)
      );
    }

    let mydiv = document.createElement('div');
    let upc_temp = [];
    this.props.sitecoreContext.route.fields.productTints.forEach((upc) => {
      upc_temp.push(upc.fields.EanCode.value);
    });
    let upc_list = upc_temp.join();

    mydiv.innerHTML = `
				<dsf-app
        id="dsf-element"
<<<<<<< HEAD
				tenant = "${url.searchParams.get("tenant") || "lorealsa"}"
				application = "${url.searchParams.get("application") || "vto"}"
				customer = "${url.searchParams.get("customer") || "laprovencalebio"}"
				country = "${url.searchParams.get("country") || "fra"}"
				environment = "${url.searchParams.get("environment") || "production"}"
				touchpoint = "${url.searchParams.get("touchpoint") || "web"}"
				type = "${url.searchParams.get("type") || "std"}"
=======
				tenant = "${url.searchParams.get('tenant') || 'lorealsa'}"
				application = "${url.searchParams.get('application') || 'vto'}"
				customer = "${url.searchParams.get('customer') || 'laprovencalebio'}"
				country = "${url.searchParams.get('country') || 'fra'}"
				environment = "${url.searchParams.get('environment') || 'production'}"
				touchpoint = "${url.searchParams.get('touchpoint') || 'web'}"
				type = "${url.searchParams.get('type') || 'std'}"
>>>>>>> develop-lpbo-fra-sitecore10
				customer-embed = "false"
				loadingbg = "#000"
				loadingmsg = "loading"
				csrf_token = "$WEBSITE_TOKEN"
				switch_ecom = "true"
				switch_autostart = "true"
        vto_mode = "singleProduct"
        vto_category = "${this.props.sitecoreContext.route.fields.productVTOCategory.value}"
        vto_upc_list = "${upc_list}"
        vto_upc_selected = "${
          this.props.sitecoreContext.route.fields.productTints[this.state.selectedTint].fields
            .EanCode.value
        }"
			/>`;

    if (!document.querySelector('#dsf-element')) {
      document.body.appendChild(mydiv);
      window.dsfApp = document.createElement('div');
      window.dsfApp.registerButtons = function () {};

      async function loadScripts(script_urls) {
        function load(script_url) {
          return new Promise(function (resolve, reject) {
            if (loadScripts.loaded.has(script_url)) {
              resolve();
            } else {
              var script = document.createElement('script');
              script.onload = resolve;
              script.src = script_url;
              script.id = 'dropper-script';
              document.head.appendChild(script);
            }
          });
        }
        var promises = [];
        for (const script_url of script_urls) {
          promises.push(load(script_url));
        }
        await Promise.all(promises);
        for (const script_url of script_urls) {
          loadScripts.loaded.add(script_url);
        }
      }
      loadScripts.loaded = new Set();

      (async () => {
        await loadScripts([
          'https://dsf-cdn-staging.loreal.io/dropper.js' + '?v=' + getRandomInt(1000),
        ]);

        window.dsfApp.registerButtons();
        window.dsfApp.addEventListener('onLoadComplete', (e) => {
          console.log(`${e.detail.application} loaded`);
        });
        window.dsfApp.addEventListener('onReady', (e) => {
          console.log('ready');
        });
      })();

      function getRandomInt(max) {
        return Math.floor(Math.random() * max);
      }
    }
  }

  handleToggleAccordion(e) {
    const selectedParent = e.currentTarget.parentElement.parentElement.parentElement;
    selectedParent.classList.toggle('open');
    document.querySelectorAll('.card').forEach((elem) => {
      if (elem !== selectedParent) elem.classList.remove('open');
    });
  }

  handleClickTint(index) {
    this.setState({ selectedTint: index });
    document.getElementById('dsf-element').setAttribute('vto_upc_selected',this.props.sitecoreContext.route.fields.productTints[index].fields.EanCode.value);

    //Change tint param un URL
    let params = new URLSearchParams(window.location.href.search);
    params.set(
      'tint',
      this.props.sitecoreContext.route.fields.productTints[index].fields.Name.value.toLowerCase()
    );
    params.set(
      'ean',
      this.props.sitecoreContext.route.fields.productTints[index].fields.EanCode.value
    );
    window.history.replaceState(
      {},
      '',
      decodeURIComponent(`${window.location.pathname}?${params}`)
    );
  }

  render() {
    const productData = this.props.sitecoreContext.route.fields;
    let EANCODE = this.props.sitecoreContext.route.fields.codeEan.value;
    let selectedTintId = productData.productTints[this.state.selectedTint]
      ? this.state.selectedTint
      : 0;

    if (productData.productTints && productData.productTints.length !== 0) {
      // If makeup product
      EANCODE = productData.productTints[selectedTintId].fields.EanCode.value;
    }
    return (
      <Fragment>
        <div className="joinstory-container">
          {/* JOINSTORY */}
          {this.props.sitecoreContext.route.fields.joinStoryID.value !== '' && (
            <Fragment>
              <div
                id={'widget-' + this.props.sitecoreContext.route.fields.joinStoryID.value}
                style={{ margin: '5px 2px', minHeight: '81px' }}
              >
                <a href="https://la-provencale-bio.my.join-stories.com/network-link.html">
                  La Provençale Bio Stories
                </a>
              </div>
            </Fragment>
          )}
        </div>
        <div className="row_custom_small summary-container">
          <div className="col-sm-7 col-xl-6 entry-image carousel_custom_product_5">
            <ProductImagesGalleryV2 selectedTint={selectedTintId} />
          </div>
          <React.Fragment>
            <div className="col-sm-5 col-xl-6 entry-summary carousel_custom_product_7">
              <div className="summary">
                <h1 className="product_title entry-title">
                  <Text field={productData && productData.title} />
                </h1>
                <div className="product-excerpt">
                  <RichText tag="p" field={productData && productData.productShortDescription} />
                </div>
                <div className="commerce-product-rating">
                  <div className="row">
                    <div className="rating col-12 pl-0 pt-0">
                      {parseInt(productData.rating.value) !== 0 && (
                        <a
                          href="#rating-container"
                          style={{ display: 'inline-flex', marginRight: '20px' }}
                        >
                          <ProductRating productid={productData} /> &nbsp;
                          <ProductReviews productId={this.props.sitecoreContext.itemId} />
                        </a>
                      )}
                      <a
                        onClick={() => handleWriteReview(productData.title.value, EANCODE)}
                        href="#review_form_wrapper"
                        style={{ textDecoration: 'underline' }}
                      >
                        Ajouter un commentaire
                      </a>
                    </div>
                  </div>
                </div>
                {productData.productTints && productData.productTints.length !== 0 && (
                  <div className="tint-makeup">
                    <div className="selected-tint">
                      <p>{productData.productTints[selectedTintId].fields.Name.value}</p>
                      <ReactTooltip backgroundColor="#21552F" textColor="#FFF" />
                    </div>
                    <div className="row tintList">
                      {productData.productTints.map((item, index) => (
                        <div
                          key={index}
                          onClick={() => this.handleClickTint(index)}
                          style={{ backgroundColor: `${item.fields.Color.value}` }}
                          data-tip={item.fields.Name.value}
                          className={
                            selectedTintId === index ? 'active tint-bubble' : 'tint-bubble'
                          }
                        ></div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="row buy-btn-ctn">
                  <div className="buy-btn-wrapper">
                    <a
                      onClick={() => handleClickToBuy(productData.title.value, EANCODE)}
                      data-eans={EANCODE}
                      className="swn-tag-wtb-btn buy-btn"
                    >
                      {i18next.t('Acheter')}
                    </a>
                  </div>
                  {productData.productTints &&
                    productData.productTints.length !== 0 &&
                    productData.productVTOCategory.value !== '' && (
                      <div className="try-btn-wrapper">
                        <a
                          onClick={() => handleClickVTO(productData.title.value, EANCODE)}
                          className="try-btn button-open-dsf-app"
                          href="#"
                        >
                          ESSAYER VIRTUELLEMENT
                          <div id="dsf-container"></div>
                        </a>
                      </div>
                    )}
                </div>

                <div className="accordion" id="product_accordion">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0 mt-0 mt-0">
                        <button
                          onClick={(e) => this.handleToggleAccordion(e)}
                          className="btn btn-link collapsed text-uppercase text-left mr-2"
                          type="button"
                          data-toggle="collapse"
                          data-target="#Details"
                          aria-expanded="false"
                          aria-controls="Details"
                        >
                          {i18next.t('DetailsLabel')}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="Details"
                      className="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#product_accordion"
                    >
                      <div className="card-body">
                        <span className="double_cat">
                          <RichText
                            field={productData && productData.productLongDescription}
                            tag="span"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0 mt-0">
                        <button
                          onClick={(e) => this.handleToggleAccordion(e)}
                          className="btn btn-link collapsed text-uppercase text-left mr-2"
                          type="button"
                          data-toggle="collapse"
                          data-target="#use"
                          aria-expanded="false"
                          aria-controls="use"
                        >
                          {i18next.t('HowToUseLabel')}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="use"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#product_accordion"
                    >
                      <div className="card-body">
                        <RichText field={productData && productData.howToUse} />
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0 mt-0">
                        <button
                          onClick={(e) => this.handleToggleAccordion(e)}
                          className="btn btn-link collapsed text-uppercase text-left mr-2"
                          type="button"
                          data-toggle="collapse"
                          data-target="#ingredient"
                          aria-expanded="false"
                          aria-controls="ingredient"
                        >
                          {i18next.t('IngredientsLabel')}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="ingredient"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#product_accordion"
                    >
                      <div className="card-body">
                        <RichText field={productData && productData.ingredientsText} />
                      </div>
                    </div>
                  </div>
                  {/*---------------- PIL -------------*/}
                  {this.props.sitecoreContext.route.fields.GlobalFootprintScore.value && (
                    <div className="card">
                      <div className="card-header" id="headingFour">
                        <h2 className="mb-0 mt-0">
                          <button
                            onClick={(e) => this.handleToggleAccordion(e)}
                            className="btn btn-link collapsed text-uppercase text-left mr-2"
                            type="button"
                            data-toggle="collapse"
                            data-target="#pil"
                            aria-expanded="false"
                            aria-controls="pil"
                          >
                            {i18next.t('PIL-general-title')}
                          </button>
                        </h2>
                      </div>
                      <div
                        id="pil"
                        className="collapse"
                        aria-labelledby="headingFour"
                        data-parent="#product_accordion"
                      >
                        <div className="card-body">
                          <ProductPIL />
                        </div>
                      </div>
                    </div>
                  )}
                  {productData && productData.environmentText.value !== "" &&
                    <div className="card">
                    <div className="card-header" id="headingFive">
                      <h2 className="mb-0 mt-0">
                        <button
                          onClick={(e) => this.handleToggleAccordion(e)}
                          className="btn btn-link collapsed text-uppercase text-left mr-2"
                          type="button"
                          data-toggle="collapse"
                          data-target="#environment"
                          aria-expanded="false"
                          aria-controls="environment"
                        >
                          {i18next.t('EnvironmentLabel')}
                        </button>
                      </h2>
                    </div>
                    <div
                      id="environment"
                      className="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#product_accordion"
                    >
                      <div className="card-body">
                        <RichText field={productData && productData.environmentText} />
                      </div>
                    </div>
                  </div>
                  }                
                </div>
              </div>
            </div>
          </React.Fragment>
          <div className={'section featuredPosts leftWhite featuredProductPage'}>
            <div className="first-part">
              <div className="row">
                <div className="contentTitle">
                  <RichText
                    field={productData && productData.ingredientTitle}
                    tag={'h2'}
                    className={'title'}
                  />
                </div>
              </div>
            </div>
            <div className="second-part">
              <div className="row">
                {productData.productIngredients &&
                  productData.productIngredients.map((listItem, index) => (
                    <div className="contents" key={`sharedListItem-${index}`}>
                      <div className="text-center">
                        <Image loading="lazy" field={listItem.fields.Image} />
                      </div>
                      <Text field={listItem.fields.Title} tag={'h4'} className={'title'} />
                      <RichText
                        field={listItem.fields.FullDescription}
                        tag={'div'}
                        className={'content'}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withSitecoreContext()(ProductSummary);
